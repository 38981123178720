<script>
/* eslint-disable */

export default {
    name: 'Awards',
    components:{
    },
    data() {
        return{
            awardsList:[
                {
                    title: "生成AIを用いたシラバス情報の拡張と授業内トピック間類似度評価の検討",
                    conference: "情報処理学会 第41回教育学習支援情報システム研究会(CLE41), 2023.12",
                    url: "https://ipsj.ixsq.nii.ac.jp/ej/index.php?active_action=repository_view_main_item_detail&page_id=13&block_id=8&item_id=231228&item_no=1"
                },
                {
                    title: "数学問題文を入力とした3Dモデル自動生成システムの検討",
                    conference: "情報処理学会 第42回教育学習支援情報システム研究会(CLE42), 2024.03",
                    url: "https://ipsj.ixsq.nii.ac.jp/ej/?action=repository_uri&item_id=233554"
                },
            ]
        }
    },
}
</script>

<template>
    <!-- Awards -->
    <section class="resume-section" id="awards">
        <div class="resume-section-content">
            <h2 class="mb-5">achievements</h2>
            <ul class="fa-ul mb-0">
                <!-- 箇条書きをv-forで記載．データはscriptタグ．マークはサブコンポーネント -->
                <li v-for="award in awardsList">
                    <span class="fa-li"><i class="fas fa-lg fa-trophy trophy-color"></i></span>
                    {{ award.title }} <br>
                    {{ award.conference }} <br>
                    <a :href="award.url" target="_blank" rel="noopener noreferrer">link</a>
                </li>
            </ul>
        </div>
    </section>
</template>

<style>
.trophy-color {
    color: var(--sub-color);
}
</style>