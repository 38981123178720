<script>
/* eslint-disable */
export default {
    name: 'LaptopCode'  // コンポーネントの名前を設定
} 
</script>

<template>
    <i class="fa-solid fa-lg fa-laptop-code school-color"></i>
</template>

<style>
.school-color {
    color: var(--sub-color);
}
</style>