<script>
/* eslint-disable */
export default {
    name: 'PersonWalking'  // コンポーネントの名前を設定
} 
</script>

<template>
    <i class="fa-solid fa-lg fa-person-walking person-walking-color"></i>
</template>

<style> 
.person-walking-color {
    color: var(--sub-color);
}
</style>