<script>
/* eslint-disable */
// import ManArrow from "./subcomponents/ManArrow"

export default {
    name: 'Awards',
    components:{
        // ManArrow
    },
    data() {
        return{
            visionList:[
                "あああああ",
                "いいいい",
                
            ]
        }
    },
}
</script>

<template>
<!-- Interests-->
    <section class="resume-section" id="vision">
        <div class="resume-section-content">
            <h2 class="mb-5">Vision</h2>
            <ul class="fa-ul mb-0">
                <!-- 箇条書きをv-forで記載．データはscriptタグ．マークはサブコンポーネント -->
                <li v-for="vision in visionList">
                    <!-- <ManArrow/> -->
                    <span class="fa-li"><i class="fa-solid fa-lg fa-person-walking-arrow-right"></i></span>
                    {{ vision }}
                </li>
            </ul>
        </div>
    </section>
</template>