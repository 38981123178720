<script>
/* eslint-disable */
import PersonWalking from "./icons/PersonWalking.vue"
import Person from "./icons/Person.vue"
import School from "./icons/School.vue"
import LaptopCode from "./icons/LaptopCode.vue"
import PersonRunning from "./icons/PersonRunning.vue"

export default {
    name: 'Background',
    components:{
        PersonWalking, 
        School,
        LaptopCode,
        PersonRunning,
    },
    data() {
        return{
            childExList: [
                { parts: [{ text: "香川県に生まれる" }], icon: Person},
                { parts: [{ text: "Window XPに触れ、色々なアプリで遊ぶ" }], icon: LaptopCode },
                { parts: [
                    { text: "高松市立三溪小学校", url: "https://www.edu-tens.net/syoHP/sankeiHP/" },
                    { text: "に入学" }] , 
                 icon: School },
                { parts: [
                    { text: "ロボカップ", url: "https://www.robocupjunior.jp" },
                    { text: "でプログラミングに出会う" }],
                 icon: LaptopCode },
                { parts: [
                    { text: "ロボカップ", url: "https://www.robocupjunior.jp" },
                    { text: "全国大会に3度出場" }],
                 icon: LaptopCode },
            ],
            highschlExList: [
                { parts: [
                        { text: "高松市立龍雲中学校", url: "https://www.fureai-cloud.jp/j-ryuun/" },
                        { text: "に入学" }],
                 icon: School },
                { parts: [{ text: "中高は陸上部に所属（専門は400m, マイルリレー）" }], icon: PersonRunning },
                { parts: [{ text: "中学の生徒会でPCスキルが活躍する" }], icon: LaptopCode },
                { parts: [
                    { text: "高松第一高校", url: "http://www.taka-ichi-h.ed.jp" },
                    { text: "に入学" },
                ] , icon: School },
                { parts: [
                    { text: "SSH", url: "https://www.mext.go.jp/a_menu/jinzai/gakkou/1309941.htm" },
                    { text: "のカリキュラムで研究を行う" },
                ] , icon: LaptopCode },
                { parts: [
                    { text: "SSH", url: "https://www.mext.go.jp/a_menu/jinzai/gakkou/1309941.htm" },
                    { text: "研究で全国大会に出場（ポスター発表）" },
                ], icon: LaptopCode },
            ],
            univExList: [
                { parts: [
                    { text: "九州大学 工学部 電気情報工学科", url: "https://www.eecs.kyushu-u.ac.jp" },
                    { text: "に入学" },
                ], icon: School },
                { parts: [{ text: "開幕コロナ禍でオンライン授業を経験する" }], icon: Person  },
                { parts: [{ text: "個別指導塾でアルバイトをする" }], icon: Person  },
                { parts: [
                    { text: "ボート部", url: "http://kurc.tv" },
                    { text: "の主将兼主務として，運営に尽力する" },
                ], icon: PersonRunning },
                { parts: [
                    { text: "イメージ・メディア理解研究室(LIMU)", url: "https://limu.ait.kyushu-u.ac.jp" },
                    { text: "に配属" },
                ], icon: LaptopCode },
                { parts: [
                    { text: "福岡未踏プロジェクト", url: "https://mitou-fukuoka.org/2023/07/26/saitaku_phase1/" },
                    { text: "に採択される" },
                ], icon: LaptopCode },
                { parts: [{ text: "研究会・学会等に積極的に参加する" }], icon: LaptopCode },
                { parts: [
                    { text: "九州大学大学院 システム情報科学府 情報理工学専攻", url: "http://www.isee.kyushu-u.ac.jp" },
                    { text: "に入学" },
                ], icon: School },
            ],
        }
    },
}
</script>

<template>
    <!-- Background-->
    <section class="resume-section" id="background">
        <div class="resume-section-content">
            <h2 class="mb-5">Background</h2>
            <!-- マイブロック -->
            <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1">
                    <div class="subheading mb-3">幼少期 - 小学生</div>
                    <ul class="fa-ul mb-0">
                        <!-- 箇条書きをv-forで記載．データはscriptタグ．マークはサブコンポーネント -->
                        <li v-for="(item, index) in childExList" :key="index">
                            <!-- アイコンコンポーネントを動的に変更 -->
                            <span class="fa-li"> <component :is="item.icon"/> </span>
                            <template v-for="part in item.parts">
                                <a v-if="part.url" :href="part.url" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">{{ part.text }}</a>
                                <span v-else>{{ part.text }}</span>
                            </template>
                        </li>
                    </ul>
                </div>
                <div class="flex-shrink-0"><span class="text-primary">2001年 - 2014年</span></div>
            </div>
            <!-- マイブロック -->
            <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1">
                    <div class="subheading mb-3">中学生 - 高校生</div>
                    <ul class="fa-ul mb-0">
                        <!-- 箇条書きをv-forで記載．データはscriptタグ．マークはサブコンポーネント -->
                        <li v-for="(item, index) in highschlExList" :key="index">
                            <!-- アイコンコンポーネントを動的に変更 -->
                            <span class="fa-li"> <component :is="item.icon"/> </span>
                            <template v-for="part in item.parts">
                                <a v-if="part.url" :href="part.url" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">{{ part.text }}</a>
                                <span v-else>{{ part.text }}</span>
                            </template>
                        </li>
                    </ul>
                </div>
                <div class="flex-shrink-0"><span class="text-primary">2014年 - 2020年</span></div>
            </div>
            <!-- マイブロック -->
            <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1">
                    <div class="subheading mb-3">大学生- 大学院生</div>
                    <ul class="fa-ul mb-0">
                        <!-- 箇条書きをv-forで記載．データはscriptタグ．マークはサブコンポーネント -->
                        <li v-for="(item, index) in univExList" :key="index">
                            <!-- アイコンコンポーネントを動的に変更 -->
                            <span class="fa-li"> <component :is="item.icon"/> </span>
                            <template v-for="part in item.parts">
                                <a v-if="part.url" :href="part.url" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">{{ part.text }}</a>
                                <span v-else>{{ part.text }}</span>
                            </template>
                        </li>
                    </ul>
                </div>
                <div class="flex-shrink-0"><span class="text-primary">2020年 - 現在</span></div>
            </div>
            <!-- ブロック
            <div class="d-flex flex-column flex-md-row justify-content-between">
                <div class="flex-grow-1">
                    <h3 class="mb-0">James Buchanan High School</h3>
                    <div class="subheading mb-3">Technology Magnet Program</div>
                    <p>GPA: 3.56</p>
                </div>
                <div class="flex-shrink-0"><span class="text-primary">August 2002 - May 2006</span></div>
            </div> -->
        </div>
    </section>
</template>

<style>
/* 箇条書き */
.my-bulletpoints {
    list-style-type: none;
    padding: 1em;
}
.my-bulletpoints li {
    display: flex;
    align-items: center;
    gap: 0 10px;
    padding: .3em;
}
.my-bulletpoints li::before {
    transform: rotate(-45deg);
    width: .6em;
    height: .6em;
    border-bottom: 3px solid var(--sub-color);
    border-right: 3px solid var(--sub-color);
    content: '';
}
</style>