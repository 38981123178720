<script>
/* eslint-disable */
import LaptopCode from './icons/LaptopCode.vue';

export default {
    name: 'Skills',
    components:{
        LaptopCode
    },
    data() {
        return{
            skillList:[
                "OpenAI API",
                "Flask",
                "spaCy, Gensim",
                "Adobe Illustrator, Photoshop",
                "DaVinci Resolve",
            ]
        }
    },
}
</script>

<template>
    <!-- Skills -->
    <section class="resume-section" id="skills">
        <div class="resume-section-content">
            <h2 class="mb-5">Skills</h2>
            <Check/>
            <div class="subheading mb-3">基本プログラミング言語</div>
            <ul class="list-inline dev-icons skill-icon-style">
                <li class="list-inline-item"><i class="fa-brands fa-python"></i></li>
                <li class="list-inline-item"><i class="fa-solid fa-c"></i></li>
                <li class="list-inline-item"><i class="fa-brands fa-vuejs"></i></li>
                <li class="list-inline-item"><i class="fab fa-html5"></i></li>
                <li class="list-inline-item"><i class="fab fa-css3-alt"></i></li>
                <li class="list-inline-item"><i class="fab fa-js-square"></i></li>
                <li class="list-inline-item"><i class="fa-brands fa-node"></i></li>
                <li class="list-inline-item"><i class="fab fa-npm"></i></li>
                <li class="list-inline-item"><i class="fa-brands fa-docker"></i></li>
            </ul>
            <div class="subheading mb-3">その他ライブラリなど</div>
            <ul class="fa-ul mb-0">
                <!-- 箇条書きをv-forで記載．データはscriptタグ．マークはサブコンポーネント -->
                <li v-for="skill in skillList">
                    <span class="fa-li"><LaptopCode/></span>
                    {{ skill }}
                </li>
            </ul>
        </div>
    </section>
</template>

<style>
.skill-icon-style {
    color: var(--sub-color);
}
.check-color {
    color: var(--sub-color);
}
</style>