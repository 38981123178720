<script>
/* eslint-disable */
export default {
    name: 'Person'  // コンポーネントの名前を設定
} 
</script>

<template>
    <i class="fa-solid fa-lg fa-person person-color"></i>
</template>

<style> 
.person-color {
    color: var(--sub-color);
}
</style>