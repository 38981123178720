<script>
/* eslint-disable */

import Navigation from "./components/Navigation"
import About from "./components/About"
import Products from "./components/Products"
import Background from "./components/Background"
import Skills from "./components/Skills"
import Vision from "./components/Vision"
import Awards from "./components/Awards"
import CopyRight from "./components/CopyRight"

export default {
  name: 'App',
  components: {
    Navigation,
    About,
    Products,
    Vision,
    Awards,
    Skills,
    Background,
    CopyRight
  }
}
</script>

<template>
  <div id="app">
    <!-- Navigation -->
    <Navigation/>
    <!-- <h2 style="margin: 10%;">このwebサイトは作成中です...</h2> -->
    <p style="margin: 0%;">このwebサイトは作成中です...</p>

    <!-- Page Content-->
    <div class="container-fluid p-0 my-style">
      <!-- About-->
        <About/>
        <hr class="m-0" />
      <!-- Products -->
        <Products/>
        <hr class="m-0" />
      <!-- Background -->
        <Background/>
        <hr class="m-0" />
      <!-- Skills -->
        <Skills/>
        <hr class="m-0" />
      <!-- Vision-->
        <!-- <Vision/>
        <hr class="m-0" /> -->
      <!-- Awards-->
        <Awards/>
        <hr class="m-0" />
      <!-- CopyRight -->
        <CopyRight/>
      </div>

  </div>
</template>

<style>
:root{
	--main-color: #555;
	--sub-color: #6d84ae;
	--emphasized-color: #bf7d97;
  --test-color: #128458;
  --bs-body-font-family: "メイリオ", Segoe UI, Muli, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

/* p文字などの基本色 */
body {
  color: var(--main-color);
}

/* 日付の色 */
.text-primary {
  --bs-text-opacity: 1;
  color: var(--emphasized-color);
}

/* リンクの色（メールなど） */
a {
    color: var(--sub-color);
}
/* カーソルオン時 */
a:hover {
  color: var(--emphasized-color);
}

/* アイコン付き箇条書き */
.fa-ul li {
    margin-bottom: 10px;
}

h2 {
  font-family: "Saira Extra Condensed";
}
</style>
