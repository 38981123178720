<script>
/* eslint-disable */
export default {
    name: 'PersonWalking'  // コンポーネントの名前を設定
} 
</script>

<template>
    <i class="fa-solid fa-school school-color"></i>
</template>

<style>
.school-color {
    color: var(--sub-color);
    font-size:1.15em;
}
</style>