<script>
/* eslint-disable */
export default {
    name: 'Products',
    components:{
    },
    data() {
        return{
            techList:{
                rittai: ["OpenAI API", "Flask", "Vue.js"],
                yukata: ["OpenAI API", "Segment Anything Model (SAM)", "FileMaker"],
                portfolio: ["Vue.js", "Bootstrap", "Firebase Hosting", "ドメイン取得"],
                atelier: ["Adobe Illustrator", "PowerPoint"]
            }
        }
    },
}
</script>

<template>
    <!-- Products-->
    <section class="resume-section" id="products">
        <div class="resume-section-content">
            <h2 class="mb-5">Products</h2>
            <!-- コンテンツブロック -->
            <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1">
                    <h3 class="mb-2">福岡未踏プロジェクト2023 Grow</h3>

                    <div class="mb-3">
                    <div v-for="tech in techList.rittai" :key="tech" style="display: inline-block;">
                        <a class="my-radius-box">{{ tech }}</a> &thinsp;
                    </div>
                    </div>

                    <p class="my-description">
                        2023年6月から10月にかけて、福岡未踏クリエータとして「RittAI Canvas: 数学問題文を入力とした3Dモデル自動生成システム」の提案および開発を行なった。 プロジェクトは提案書の書類審査と面接を経て、1期Growコースの開発プロジェクトとして採択され、50万円の開発支援金を得て進められた。
                        開発したアプリは、空間図形問題に取り組む学習者をサポートし、黒板や紙のような平面上では成し得ない指導方法を目指す。 ユーザーは空間図形の問題を写真やテキスト形式でアップロードするだけで、AIが操作可能な3Dモデルを自動で生成する。
                    </p>
                    <a class="overhamon-button" href="https://mitou-fukuoka.org/works/【1期grow】rittai-canvas：数学問題文を入力とした3dモデル自動/" target="_blank" rel="noopener noreferrer">
                        <i class="fa fa-external-link"></i> プロジェクト紹介ページ
                    </a>
                    <br>
                    <a class="overhamon-button" href="https://note.com/mitou_fukuoka/n/nfa9f5801baf2" target="_blank" rel="noopener noreferrer" style="width: auto; margin-right: 3%;">
                        <i class="fa fa-external-link"></i> インタビュー記事
                    </a>
                    <br>
                    <a class="overhamon-button" href="https://mitou-fukuoka.org" target="_blank" rel="noopener noreferrer" style="margin: 0 1% 0 auto;">
                        <i class="fa fa-external-link"></i> 福岡未踏 公式ページはこちら
                    </a>
                </div>
                <div class="flex-shrink-0"><span class="text-primary">2023年8月 - 11月</span></div>
            </div>
            <!-- コンテンツブロック -->
            <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1">
                    <h3 class="mb-2">浴衣柄DXプロジェクト</h3>

                    <div class="mb-3">
                    <div v-for="tech in techList.yukata" :key="tech" style="display: inline-block;">
                        <a class="my-radius-box">{{ tech }}</a> &thinsp;
                    </div>
                    </div>
                    
                    <p class="my-description">
                        九大芸術工学部の先生からの依頼を受けた開発プロジェクト。
                        先生の所有する「浴衣柄の型紙画像」に対し、AIによるカテゴリ分類・オブジェクト特定・説明文生成がどのような結果になるのか、すなわちAIが浴衣柄をどう解釈するのかを観察した。
                        研究室内でプロジェクトチームを組み、SAMによる画像解析、局所特徴量クラスタリング、GPT4による文章生成プログラムを実装し、FileMaker DBとして納品した。
                    </p>
                </div>
                <div class="flex-shrink-0"><span class="text-primary">2024年2月 - 3月</span></div>
            </div>
            <!-- コンテンツブロック -->
            <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1">
                    <h3 class="mb-2">ポートフォリオサイト</h3>

                    <div class="mb-3">
                    <div v-for="tech in techList.portfolio" :key="tech" style="display: inline-block;">
                        <a class="my-radius-box">{{ tech }}</a> &thinsp;
                    </div>
                    </div>

                    <p class="my-description">
                        就職活動に向けて作成したこのサイト。webデザイン・配色の勉強とVueの学び直しを兼ねている。
                        マテリアルデザイン、くすみカラーを意識してデザインした。
                    </p>
                </div>
                <div class="flex-shrink-0"><span class="text-primary">2024年4月 - 5月</span></div>
            </div>
            <!-- コンテンツブロック -->
            <div class="d-flex flex-column flex-md-row justify-content-between">
                <div class="flex-grow-1">
                    <h3 class="mb-2">尾崎のアトリエ（近日公開）</h3>

                    <div class="mb-3">
                    <div v-for="tech in techList.atelier" :key="tech" style="display: inline-block;">
                        <a class="my-radius-box">{{ tech }}</a> &thinsp;
                    </div>
                    </div>

                    <p class="my-description">
                        今までに作ってきたパンフレット、ロゴなどの作品をまとめた。（プロダクトではない）
                    </p>
                    <a class="overhamon-button" href="https://ozakimahiroportfolio.notion.site/abaacc31f7b74808aa547f4a5bef92f2" target="_blank" rel="noopener noreferrer" style="margin: 0 1% 0 auto;">
                        <i class="fa fa-external-link"></i> 尾崎のアトリエ はこちら！
                    </a>
                </div>
                <div class="flex-shrink-0"><span class="text-primary">2020年8月 - 現在</span></div>
            </div>
        </div>
    </section>
</template>

<style>
.my-description {
    text-align: justify !important;
}

/* .my-radius-box {
    border: solid 2px var(--main-color);     枠線指定
    color: #ffffff;
    font-weight: bold;
    background-color: var(--sub-color);     背景色指定
    border-radius: 50px;        角丸指定
    text-align: center;
    margin: 3px;
    padding: 5px;
} */

a.my-radius-box {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    background: var(--sub-color);
    padding: 3px 15px;
    margin: 4px 0px;
    border-radius: 50px;
    outline: 1px solid;
    outline-color: var(--sub-color);
    outline-offset: 0px;
    display: inline-block;
    /* transition: .3s; */
    text-decoration: none;
    cursor: default; /* 特定のクラスが適用されたリンクにのみカーソルをデフォルトに設定 */
}
a:hover.my-radius-box {
    animation: light .9s infinite;
    color: #fff;
}
@keyframes light {
  100% { 
    outline-color: transparent;
    outline-offset: 12px;
  }
}
</style>