<script>
/* eslint-disable */
</script>

<template>
    <!-- About-->
    <section class="resume-section" id="about">
        <div class="resume-section-content">
            <h1 class="mb-1">
                尾崎 真大
                <span class="text-primary name-eng">Ozaki Mahiro</span>
            </h1>
            <div class="subheading mb-5">
                <!-- バラバラに書いているのはスマホ表記のため -->
                <span class="part">九州大学大学院</span>
                <span class="part">システム情報科学府 情報理工学専攻</span>
                <span class="part"></span>
                <!-- <a href="mailto:name@email.com">XXXX@email.com</a> -->
            </div>
            <p class="mb-5 about-description">
                幼少期から実家のパソコン（Windows XP）に触れ、ペイントやPowerPointで遊ぶ。さらに、小学校高学年で参加した
                <a href="https://www.robocup.or.jp" target="_blank" rel="noopener noreferrer">ロボカップジュニア</a>
                にて、初めてプログラミングを体験する。
                <!-- <br> -->
                大学進学後は、Learning Analytics分野での研究・福岡未踏でのアプリ開発・業務委託による画像解析プログラムの作成に従事しつつ、趣味でPowerPointやAdobe Illustratorを用いたデザインを極める。 そして、大学院に進学し現在に至る。
            </p>
            <!-- <div class="social-icons">
                <a class="social-icon" href="#!"><i class="fab fa-linkedin-in"></i></a>
                <a class="social-icon" href="#!"><i class="fab fa-github"></i></a>
                <a class="social-icon" href="#!"><i class="fab fa-twitter"></i></a>
                <a class="social-icon" href="#!"><i class="fab fa-facebook-f"></i></a>
            </div> -->
        </div>
    </section>
</template>

<style>
/* 英語名前の色 */
.name-eng {
  color: var(--sub-color) !important;
  font-size: 60%;

}

/* 自己紹介 */
.about-description{
    text-align: justify !important;
}

/* OzakiMahiroのスマホ画面用調整 */
@media (max-width: 600px) {
  .text-primary {
    display: block; /* 画面が小さいときはブロック要素として表示 */
  }
}

/* 九州大学大学院システム情報科学府情報理工学専攻のスマホ調整 */
.part {
    display: inline; /* デフォルトではインラインで表示 */
}
@media (max-width: 768px) { /* 768px以下で改行を挿入 */
    .part {
        display: block; /* ブロック要素として表示 */
        font-size: 80%;
    }
}
</style>