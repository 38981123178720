<script>
/* eslint-disable */

window.addEventListener('DOMContentLoaded', event => {

// Activate Bootstrap scrollspy on the main nav element
const sideNav = document.body.querySelector('#sideNav');
if (sideNav) {
    new bootstrap.ScrollSpy(document.body, {
        target: '#sideNav',
        rootMargin: '0px 0px -40%',
    });
};

// Collapse responsive navbar when toggler is visible
const navbarToggler = document.body.querySelector('.navbar-toggler');
const responsiveNavItems = [].slice.call(
    document.querySelectorAll('#navbarResponsive .nav-link')
);
responsiveNavItems.map(function (responsiveNavItem) {
    responsiveNavItem.addEventListener('click', () => {
        if (window.getComputedStyle(navbarToggler).display !== 'none') {
            navbarToggler.click();
        }
    });
});

});
</script>

<template>
    <body id="page-top">
        <!-- Navigation-->
        <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
            <a class="navbar-brand js-scroll-trigger" href="#page-top">
                <span class="d-block d-lg-none">Ozaki Mahiro</span>
                <span class="d-none d-lg-block"><img class="img-fluid img-profile rounded-circle mx-auto mb-2" src="../assets/img/profile.jpg" alt="..." /></span>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">About</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#products">Products</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#background">Background</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#skills">Skills</a></li>
                    <!-- <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#vision">Vision</a></li> -->
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#awards">achievements</a></li>
                </ul>
            </div>
        </nav>
    </body>
</template>

<style>
/* 左帯の色 */
.bg-primary {
    --bs-bg-opacity: 1;
    background-color: var(--sub-color) !important;
}

/* 左帯上のリンクの色 */
.navbar-nav {
  --bs-nav-link-color: #edf3fb;
  --bs-nav-link-hover-color: var(--emphasized-color);
  --bs-nav-link-disabled-color: var(--main-color);
}
/* フォーカスされた時の色 */
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: #baaeae;
}
</style>