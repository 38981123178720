<script>
/* eslint-disable */
export default {
    name: 'PersonRunning'  // コンポーネントの名前を設定
} 
</script>

<template>
    <i class="fa-solid fa-lg fa-person-running person-running-color"></i>
</template>

<style>
.person-running-color {
    color: var(--sub-color);
}
</style>